"use client";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import Blog from "./pages/Blog/Blog";
import Article from "./pages/Blog/Articles/Article";
import Projects from "./pages/Projects/Projects";
import Project from "./pages/Projects/Project/Project";
import Contact from "./pages/Contact/Contact";
import NotFound from "./pages/404/404";
import Pong from "./pages/Pong/Pong";
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Toaster } from "./components/ui/toaster";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="theme-color" content="#000000" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href="https://varadan.xyz" />
            <link
              rel="preload"
              href="https://images.fineartamerica.com/images-medium-large-5/modern-abstract-expressive-lost-ginette-callaway.jpg"
              as="image"
              fetchpriority="high"
            />
          </Helmet>
          <Toaster />
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<Article />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:id" element={<Project />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/pong" element={<Pong />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </div>
        </Router>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
