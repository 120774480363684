import React from "react";
import { Link } from "react-router-dom";
import "../../index.css";
import "./Blog.css";
import { useArticles } from "../../utils/githubApi";
import { Helmet } from "react-helmet-async";

function Blog() {
  const { data: posts, isLoading, error } = useArticles();

  return (
    <div className="h-screen overflow-hidden bg-stone-100">
      <Helmet>
        <title>Writing</title>
        <meta
          name="description"
          content="Articles on software engineering, AI research, and technology by Varadan Kalkunte."
        />
        <meta
          name="keywords"
          content="blog, articles, software engineering, AI, technology, Varadan Kalkunte"
        />
        <meta property="og:title" content="Writing | Varadan Kalkunte" />
        <meta
          property="og:description"
          content="Varadan Kalkunte writes about software engineering, AI research, and technology."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://varadan.xyz/blog" />
        <link rel="canonical" href="https://varadan.xyz/blog" />
      </Helmet>
      <div className="container">
        <header>
          <div className="site-title">
            <Link to="/">Varadan Kalkunte</Link>
          </div>
          <nav>
            <Link to="/">About</Link>
            <Link to="/blog">Writing</Link>
            <Link to="/projects">Projects</Link>
          </nav>
        </header>
        <main className="content">
          <h1 className="page-title">Writing</h1>
          <section>
            {isLoading ? (
              <p>Loading posts...</p>
            ) : error ? (
              <p style={{ color: "red" }}>Error loading posts.</p>
            ) : (
              posts.map((post) => (
                <article key={post.link} className="post">
                  <div className="post-header">
                    <div className="post-title">
                      <Link to={`/blog/${post.link}`}>{post.title}</Link>
                    </div>
                    <div className="post-meta">
                      <span className="post-date">
                        {new Date(post.date).toLocaleDateString("en-US", {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                      {post.tags && post.tags.length > 0 && (
                        <div className="post-tags">
                          {post.tags.map((tag) => (
                            <span key={tag} className="post-tag">
                              {tag}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="post-description">{post.description}</p>
                </article>
              ))
            )}
          </section>
        </main>
      </div>
    </div>
  );
}

export default Blog;
