import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../../index.css"; // Import global styles
import "./404.css"; // Import component-specific styles

// Import styles needed for the header (assuming they are in Home.css or similar)
// If header styles are not globally available, we might need a shared CSS file or component
import "../Home/Home.css"; // Example: importing Home styles for header

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 - Not Found</title>
      </Helmet>
      {/* Use the standard container and header */}
      <div className="container">
        <header>
          <div className="site-title">
            <Link to="/">Varadan Kalkunte</Link>
          </div>
          <nav>
            <Link to="/">About</Link>
            <Link to="/blog">Writing</Link>
            <Link to="/projects">Projects</Link>
          </nav>
        </header>
        {/* Use the dedicated CSS class and remove inline styles */}
        <div className="content error-state not-found-content">
          <h1>404 - Page Not Found</h1>
          <p>Oops! Looks like this page wandered off.</p>
        </div>
      </div>
      {/* Remove the custom indicator and link */}
      {/* <div className="not-found-indicator">404</div> */}
      {/* <Link to="/" className="go-home-link">Go Home</Link> */}
    </>
  );
};

export default NotFound;
