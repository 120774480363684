import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
function Header() {
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    // Check for saved theme preference or use system preference
    const savedTheme = localStorage.getItem("theme");
    const prefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    if (savedTheme === "light" || (!savedTheme && !prefersDark)) {
      setIsDarkMode(false);
      document.body.classList.add("light-mode");
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    if (isDarkMode) {
      document.body.classList.add("light-mode");
      localStorage.setItem("theme", "light");
    } else {
      document.body.classList.remove("light-mode");
      localStorage.setItem("theme", "dark");
    }
  };

  return (
    <nav className="w-full p-2 sm:p-8 backdrop-blur-sm bg-stone-20/10 z-5">
      <ul className="max-w-2xl mx-auto flex justify-end gap-6 sm:gap-8 text-base sm:text-lg">
        <li>
          <Link
            to="/"
            className="text-stone-600 hover:text-stone-800 transition-colors"
          >
            about
          </Link>
        </li>
        <li>
          <Link
            to="/blog"
            className="text-stone-600 hover:text-stone-800 transition-colors"
          >
            writing
          </Link>
        </li>
        <li>
          <Link
            to="/projects"
            className="text-stone-600 hover:text-stone-800 transition-colors"
          >
            projects
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
