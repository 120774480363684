import React from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import "katex/dist/katex.min.css"; // Import KaTeX CSS
import "../../../index.css"; // Import global styles
import "./Article.css"; // Import new CSS file
import { useArticle } from "../../../utils/githubApi";
import { Helmet } from "react-helmet-async";

// Custom component renderers
const SmallTextBlock = ({ node, children, ...props }) => (
  <p className="text-sm text-stone-500" {...props}>
    {children}
  </p>
);

function Article() {
  const { id } = useParams();
  const { data: article, isLoading, error } = useArticle(id);

  if (isLoading) {
    return (
      <div className="container">
        <header>
          <div className="site-title">
            <Link to="/">Varadan Kalkunte</Link>
          </div>
          <nav>
            <Link to="/">About</Link>
            <Link to="/blog">Writing</Link>
            <Link to="/projects">Projects</Link>
          </nav>
        </header>
        <div className="content loading-state">Loading article...</div>
      </div>
    );
  }

  if (error || !article) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <Helmet>
        <title>{article.title} | Varadan Kalkunte</title>
        <meta
          name="description"
          content={
            article.description ||
            `${article.title} - Article by Varadan Kalkunte`
          }
        />
        <meta
          name="keywords"
          content={
            article.tags
              ? article.tags.join(", ") + ", Varadan Kalkunte, blog"
              : "Varadan Kalkunte, blog, article"
          }
        />
        <meta
          property="og:title"
          content={`${article.title} | Varadan Kalkunte's Blog`}
        />
        <meta
          property="og:description"
          content={
            article.description ||
            `${article.title} - Article by Varadan Kalkunte`
          }
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://varadan.xyz/blog/${id}`} />
        {article.image && <meta property="og:image" content={article.image} />}
        <meta
          property="article:published_time"
          content={new Date(article.date).toISOString()}
        />
        {article.tags &&
          article.tags.map((tag) => (
            <meta property="article:tag" content={tag} key={tag} />
          ))}
        <link rel="canonical" href={`https://varadan.xyz/blog/${id}`} />
      </Helmet>

      <div className="container">
        <header>
          <div className="site-title">
            <Link to="/">Varadan Kalkunte</Link>
          </div>
          <nav>
            <Link to="/">About</Link>
            <Link to="/blog">Writing</Link>
            <Link to="/projects">Projects</Link>
          </nav>
        </header>

        <main className="content">
          <article>
            <div className="article-header">
              <h1 className="article-title">{article.title}</h1>
              <div className="article-meta">
                <span className="article-date">
                  {new Date(article.date).toLocaleDateString("en-US", {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                  })}
                </span>
                {article.tags && article.tags.length > 0 && (
                  <div className="article-tags">
                    {article.tags.map((tag) => (
                      <span key={tag} className="article-tag">
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {article.image && (
              <img
                src={article.image}
                alt={article.title}
                className="article-image"
              />
            )}
            <div className="markdown-content">
              <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeRaw, rehypeKatex]}
                components={{
                  small: SmallTextBlock, // Custom renderer for <small>
                }}
              >
                {article.content}
              </ReactMarkdown>
            </div>
          </article>
        </main>
      </div>
    </>
  );
}

export default Article;
