import { useQuery } from "@tanstack/react-query";

const ARTICLE_CONFIG = {
  owner: "varadanvk",
  repo: "blog",
  token: process.env.REACT_APP_GITHUB_TOKEN,
};

const PROJECTS_CONFIG = {
  owner: "varadanvk",
  repo: "projects",
  token: process.env.REACT_APP_GITHUB_TOKEN,
};

const GITHUB_CONFIG = {
  owner: "varadanvk",
  token: process.env.REACT_APP_GITHUB_TOKEN,
};

const getHeaders = () => ({
  Authorization: `Bearer ${GITHUB_CONFIG.token}`,
  Accept: "application/vnd.github.v3+json",
});

export const fetchArticlesMetadata = async () => {
  const response = await fetch(
    `https://api.github.com/repos/${ARTICLE_CONFIG.owner}/${ARTICLE_CONFIG.repo}/contents/metadata.json`,
    { headers: getHeaders() }
  );

  if (!response.ok) throw new Error("Failed to fetch metadata");

  const data = await response.json();
  const decoder = new TextDecoder("utf-8");
  const bytes = Uint8Array.from(atob(data.content), (c) => c.charCodeAt(0));
  return JSON.parse(decoder.decode(bytes));
};

export const fetchProjectsMetadata = async () => {
  const response = await fetch(
    `https://api.github.com/repos/${PROJECTS_CONFIG.owner}/${PROJECTS_CONFIG.repo}/contents/metadata.json`,
    { headers: getHeaders() }
  );

  if (!response.ok) throw new Error("Failed to fetch metadata");

  const data = await response.json();
  const decoder = new TextDecoder("utf-8");
  const bytes = Uint8Array.from(atob(data.content), (c) => c.charCodeAt(0));
  return JSON.parse(decoder.decode(bytes));
};

export const useArticles = (limit = undefined) => {
  return useQuery({
    queryKey: ["articles"],
    queryFn: async () => {
      const metadata = await fetchArticlesMetadata();
      const publishedPosts = metadata
        .filter((post) => post.status === "published")
        .map((post) => ({
          ...post,
          dateObj: new Date(post.date),
        }))
        .sort((a, b) => b.dateObj - a.dateObj)
        .map(({ dateObj, ...post }) => post);

      return limit ? publishedPosts.slice(0, limit) : publishedPosts;
    },
    staleTime: 1000 * 60 * 5,
  });
};

export const useArticle = (id) => {
  return useQuery({
    queryKey: ["article", id],
    queryFn: async () => {
      const metadata = await fetchArticlesMetadata();
      const article = metadata.find((post) => post.link === id);

      if (!article || article.status !== "published") {
        throw new Error("Article not found or not published");
      }

      const contentResponse = await fetch(
        `https://api.github.com/repos/${ARTICLE_CONFIG.owner}/${ARTICLE_CONFIG.repo}/contents/${article.title}.md`,
        { headers: getHeaders() }
      );

      if (!contentResponse.ok) throw new Error("Failed to fetch content");

      const contentData = await contentResponse.json();
      const decoder = new TextDecoder("utf-8");
      const contentBytes = Uint8Array.from(atob(contentData.content), (c) =>
        c.charCodeAt(0)
      );
      const content = decoder.decode(contentBytes);

      return {
        ...article,
        content: content.replace(/---\n([\s\S]*?)\n---/, "").trim(),
      };
    },
    staleTime: 1000 * 60 * 5,
  });
};

// Helper function to parse markdown links
const parseMarkdownLink = (markdown) => {
  if (!markdown) return null;
  const match = markdown.match(/\[(.*?)\]\((.*?)\)/);
  return match ? { text: match[1], url: match[2] } : null;
};

export const useProjects = (limit = undefined) => {
  return useQuery({
    queryKey: ["projects"],
    queryFn: async () => {
      const metadata = await fetchProjectsMetadata();
      const publishedProjects = metadata
        .filter((project) => project.status === "published")
        .map((project) => ({
          ...project,
          dateObj: new Date(project.date),
        }))
        .sort((a, b) => b.dateObj - a.dateObj)
        .map(({ dateObj, ...project }) => project);

      // Parse production links and fetch images
      const projectsWithImages = await Promise.all(
        publishedProjects.map(async (project) => {
          const processedProject = {
            ...project,
            production: project.production
              ? parseMarkdownLink(project.production)
              : null,
          };

          if (project.image) {
            try {
              const imageResponse = await fetch(
                `https://api.github.com/repos/${PROJECTS_CONFIG.owner}/${PROJECTS_CONFIG.repo}/contents/${project.image}`,
                { headers: getHeaders() }
              );
              if (imageResponse.ok) {
                const imageData = await imageResponse.json();
                return {
                  ...processedProject,
                  image: imageData.download_url,
                };
              }
            } catch (error) {
              console.error(
                `Failed to fetch image for project ${project.title}:`,
                error
              );
            }
          }
          return processedProject;
        })
      );

      return limit ? projectsWithImages.slice(0, limit) : projectsWithImages;
    },
    staleTime: 1000 * 60 * 5,
  });
};

export const useProject = (id) => {
  return useQuery({
    queryKey: ["project", id],
    queryFn: async () => {
      const metadata = await fetchProjectsMetadata();
      const project = metadata.find((post) => post.link === id);

      if (!project || project.status !== "published") {
        throw new Error("Project not found or not published");
      }

      // Parse production link
      const processedProject = {
        ...project,
        production: project.production
          ? parseMarkdownLink(project.production)
          : null,
      };

      // Fetch the project's image if it exists
      if (processedProject.image) {
        try {
          const imageResponse = await fetch(
            `https://api.github.com/repos/${PROJECTS_CONFIG.owner}/${PROJECTS_CONFIG.repo}/contents/${processedProject.image}`,
            { headers: getHeaders() }
          );
          if (imageResponse.ok) {
            const imageData = await imageResponse.json();
            processedProject.image = `data:${imageData.type};base64,${imageData.content}`;
          }
        } catch (error) {
          console.error(
            `Failed to fetch image for project ${processedProject.title}:`,
            error
          );
        }
      }

      const contentResponse = await fetch(
        `https://api.github.com/repos/${PROJECTS_CONFIG.owner}/${PROJECTS_CONFIG.repo}/contents/${processedProject.title}.md`,
        { headers: getHeaders() }
      );

      if (!contentResponse.ok) throw new Error("Failed to fetch content");

      const contentData = await contentResponse.json();
      const decoder = new TextDecoder("utf-8");
      const contentBytes = Uint8Array.from(atob(contentData.content), (c) =>
        c.charCodeAt(0)
      );
      const content = decoder.decode(contentBytes);

      return {
        ...processedProject,
        content: content.replace(/---\n([\s\S]*?)\n---/, "").trim(),
      };
    },
    staleTime: 1000 * 60 * 5,
  });
};
