import "../../index.css";
import "./Home.css";
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useArticles } from "../../utils/githubApi";
import { useToast } from "../../hooks/use-toast";
import { ToastAction } from "../../components/ui/toast";

function Home() {
  const { data: posts, isLoading, error } = useArticles(3);
  const { toast } = useToast();

  const handleCopyEmail = async () => {
    const email = "varadanvk@gmail.com";
    try {
      await navigator.clipboard.writeText(email);
      toast({
        id: Date.now().toString(),
        title: "Email Copied!",
        description: email,
        duration: 3000,
      });
    } catch (err) {
      console.error("Toast error:", err);
      toast({
        id: Date.now().toString(),
        title: "Failed to copy",
        description: "Please try copying the email again",
        variant: "destructive",
        duration: 3000,
      });
    }
  };

  return (
    <div className="h-screen overflow-hidden bg-stone-100">
      <Helmet>
        <meta
          name="description"
          content="Varadan Kalkunte's personal website featuring software engineering, AI research, and projects."
        />
        <meta
          name="keywords"
          content="Varadan Kalkunte, software engineer, AI research, portfolio, blog"
        />
        <meta
          property="og:title"
          content="Varadan Kalkunte | Software Engineer"
        />
        <meta
          property="og:description"
          content="Explore Varadan Kalkunte's work in software engineering, AI research, and personal projects."
        />
        <meta property="og:image" content="https://varadan.xyz/image.jpeg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://varadan.xyz" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@varadankalkunte" />
        <meta
          name="twitter:title"
          content="Varadan Kalkunte | Software Engineer"
        />
        <meta
          name="twitter:description"
          content="Explore Varadan Kalkunte's work in software engineering, AI research, and personal projects."
        />
        <meta name="twitter:image" content="https://varadan.xyz/image.jpeg" />
      </Helmet>

      <div className="container">
        <header>
          <div className="site-title">
            <Link to="/">Varadan Kalkunte</Link>
          </div>
          <nav>
            <Link to="/">About</Link>
            <Link to="/blog">Writing</Link>
            <Link to="/projects">Projects</Link>
          </nav>
        </header>

        <div className="content">
          <p className="intro">
            I'm a senior at Amador Valley High School in Pleasanton, CA. When
            I'm not in class, I'm building multi-agent simulations at{" "}
            <a href="https://aaru.com">Aaru</a>.
          </p>

          <section id="work">
            <h2>Previous</h2>

            <div className="entry">
              <div className="entry-title">
                Co-Founder -{" "}
                <a
                  href="https://gatekeep.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gatekeep Labs
                </a>
              </div>
              <p className="entry-description">
                Created an AI video generator and built an edtech platform.
              </p>
            </div>

            <div className="entry">
              <div className="entry-title">
                Researcher -{" "}
                <a
                  href="https://med.stanford.edu/sci3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stanford SCi3
                </a>
              </div>
              <p className="entry-description">
                Developed 3D segmentation models for early-stage tumor
                detection. Also lead development of the Stanford BiRAT Platform.
              </p>
            </div>

            <div className="entry">
              <div className="entry-title">
                Founder -{" "}
                <a
                  href="https://kairoslabs.xyz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kairos
                </a>
              </div>
              <p className="entry-description">
                Created an internal search tool for personal data sources.
                Acquired by stealth in September 2024.
              </p>
            </div>
          </section>

          <section id="writing">
            <h2>Writing</h2>
            {isLoading ? (
              <p>Loading posts...</p>
            ) : error ? (
              <p style={{ color: "red" }}>Error loading posts.</p>
            ) : (
              posts.map((post) => (
                <div key={post.link} className="post">
                  <div className="post-header">
                    <div className="post-title">
                      <Link to={`/blog/${post.link}`}>{post.title}</Link>
                    </div>
                    <span className="post-date">
                      {new Date(post.date).toLocaleDateString("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                  <p className="entry-description">{post.description}</p>
                </div>
              ))
            )}
          </section>

          <section id="contact">
            <div className="contact-links">
              <a href="https://twitter.com/varadankalkunte">Twitter</a>
              <a href="https://linkedin.com/in/varadankalkunte">LinkedIn</a>
              <a href="https://github.com/varadanvk">Github</a>
              <a href="https://instagram.com/varadankalkunte">Instagram</a>
              <button onClick={handleCopyEmail}>Email</button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Home;
