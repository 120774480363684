import React from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import "katex/dist/katex.min.css";
import "../../../index.css";
import "./Project.css";
import { useProject } from "../../../utils/githubApi";
import { Helmet } from "react-helmet-async";

function Project() {
  const { id } = useParams();
  const { data: project, isLoading, error } = useProject(id);

  if (isLoading) {
    return (
      <div className="container">
        <header>
          <div className="site-title">
            <Link to="/">Varadan Kalkunte</Link>
          </div>
          <nav>
            <Link to="/">About</Link>
            <Link to="/blog">Writing</Link>
            <Link to="/projects">Projects</Link>
          </nav>
        </header>
        <div className="content loading-state">Loading project...</div>
      </div>
    );
  }

  if (error || !project) {
    return <Navigate to="/404" replace />;
  }

  const SmallTextBlock = ({ node, children, ...props }) => (
    <p className="text-sm text-stone-500" {...props}>
      {children}
    </p>
  );

  return (
    <>
      <Helmet>
        <title>{project.title} | Varadan Kalkunte Projects</title>
        <meta
          name="description"
          content={
            project.description ||
            `${project.title} - Project by Varadan Kalkunte`
          }
        />
        <meta
          property="og:title"
          content={`${project.title} | Varadan Kalkunte's Projects`}
        />
        <meta
          property="og:description"
          content={
            project.description ||
            `${project.title} - Project by Varadan Kalkunte`
          }
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`https://varadan.xyz/projects/${id}`}
        />
        {project.image && <meta property="og:image" content={project.image} />}
        <link rel="canonical" href={`https://varadan.xyz/projects/${id}`} />
      </Helmet>

      <div className="container">
        <header>
          <div className="site-title">
            <Link to="/">Varadan Kalkunte</Link>
          </div>
          <nav>
            <Link to="/">About</Link>
            <Link to="/blog">Writing</Link>
            <Link to="/projects">Projects</Link>
          </nav>
        </header>

        <main className="content">
          <article>
            <div className="project-content-header" style={{ flexDirection: 'column', alignItems: 'stretch' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <h1 className="project-title" style={{ marginBottom: '0' }}>{project.title}</h1>
                <span className="project-date">
                  {new Date(project.date).toLocaleDateString("en-US", {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                  })}
                </span>
              </div>
              <div style={{ marginTop: '8px', marginBottom: '5px' }}>
                {project.github && (
                  <a
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: '15px' }}
                  >
                    [github]
                  </a>
                )}
                {project.production && (
                  <a
                    href={project.production.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    [{project.production.text}]
                  </a>
                )}
              </div>
            </div>

            {project.image && (
              <img
                src={project.image}
                alt={project.title}
                className="project-image"
                loading="lazy"
              />
            )}

            <div className="markdown-content">
              <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeRaw, rehypeKatex]}
                components={{
                  small: SmallTextBlock,
                }}
              >
                {project.content}
              </ReactMarkdown>
            </div>
          </article>
        </main>
      </div>
    </>
  );
}

export default Project;
