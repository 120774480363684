import React from "react";
import { Link } from "react-router-dom";
import "../../index.css";
import "./Projects.css";
import { useProjects } from "../../utils/githubApi";
import { Helmet } from "react-helmet-async";

function Projects() {
  const { data: projects, isLoading, error } = useProjects();

  return (
    <div className="h-screen overflow-hidden bg-stone-100">
      <Helmet>
        <title>Projects</title>
        <meta
          name="description"
          content="Portfolio of software engineering and AI projects by Varadan Kalkunte."
        />
        <meta
          name="keywords"
          content="projects, portfolio, software engineering, AI, Varadan Kalkunte"
        />
        <meta property="og:title" content="Projects | Varadan Kalkunte" />
        <meta
          property="og:description"
          content="View Varadan Kalkunte's software and AI project portfolio."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://varadan.xyz/projects" />
        <link rel="canonical" href="https://varadan.xyz/projects" />
      </Helmet>

      <div className="container">
        <header>
          <div className="site-title">
            <Link to="/">Varadan Kalkunte</Link>
          </div>
          <nav>
            <Link to="/">About</Link>
            <Link to="/blog">Writing</Link>
            <Link to="/projects">Projects</Link>
          </nav>
        </header>
        <main className="content">
          <h1 className="page-title">Projects</h1>
          <section>
            {isLoading ? (
              <p>Loading projects...</p>
            ) : error ? (
              <p style={{ color: "red" }}>Error loading projects.</p>
            ) : projects && projects.length > 0 ? (
              <>
                {projects.map((project) => (
                  <article key={project.link} className="post">
                    <div className="post-header">
                      <div className="post-title">
                        <Link to={`/projects/${project.link}`}>
                          {project.title}
                        </Link>
                      </div>
                      <div className="project-links">
                        {project.production && (
                          <a
                            href={project.production.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            [{project.production.text}]
                          </a>
                        )}
                        {project.github && (
                          <a
                            href={project.github}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            [github]
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="post-meta">
                      <span className="post-date">
                        {new Date(project.date).toLocaleDateString("en-US", {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                      {project.tags && project.tags.length > 0 && (
                        <div className="post-tags">
                          {project.tags.map((tag) => (
                            <span key={tag} className="post-tag">
                              {tag}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                    {project.description && (
                      <p className="post-description">{project.description}</p>
                    )}
                  </article>
                ))}
              </>
            ) : (
              <p>No projects found.</p>
            )}
          </section>
        </main>
      </div>
    </div>
  );
}

export default Projects;
