import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Layout from "../../components/Layout";
import "./Pong.css";

const Pong = () => {
  const canvasRef = useRef(null);
  const [score, setScore] = useState(0);
  const [computerScore, setComputerScore] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [winner, setWinner] = useState("");
  const [difficulty, setDifficulty] = useState(50); // Default difficulty (0-100)

  // Game state refs to avoid React render cycles affecting the game loop
  const playerScoreRef = useRef(0);
  const computerScoreRef = useRef(0);
  const gameStartedRef = useRef(false);
  const gameOverRef = useRef(false);
  const difficultyRef = useRef(50);

  // Update refs when state changes
  useEffect(() => {
    playerScoreRef.current = score;
    computerScoreRef.current = computerScore;
    gameStartedRef.current = gameStarted;
    gameOverRef.current = gameOver;
    difficultyRef.current = difficulty;
  }, [score, computerScore, gameStarted, gameOver, difficulty]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Game constants
    const paddleHeight = 100;
    const paddleWidth = 10;
    const ballRadius = 8;

    // Game state variables
    let ballX = canvas.width / 2;
    let ballY = canvas.height / 2;
    let ballSpeedX = 5;
    let ballSpeedY = 2;
    let playerY = (canvas.height - paddleHeight) / 2;
    let computerY = (canvas.height - paddleHeight) / 2;
    let computerSpeed = calculateComputerSpeed(difficultyRef.current);
    let animationFrameId;
    
    // Calculate computer speed based on difficulty (1-8)
    function calculateComputerSpeed(difficultyValue) {
      // Map 0-100 difficulty to 1-8 speed range
      return 1 + (difficultyValue / 100) * 7;
    }

    // Reset game state
    const resetBall = (direction = 1) => {
      ballX = canvas.width / 2;
      ballY = canvas.height / 2;
      ballSpeedX = 5 * direction;
      ballSpeedY = Math.random() * 4 - 2; // Random vertical speed
    };

    // Handle mouse movement for player's paddle
    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      const mouseY = e.clientY - rect.top;
      playerY = mouseY - paddleHeight / 2;

      // Keep paddle within canvas bounds
      if (playerY < 0) {
        playerY = 0;
      }
      if (playerY > canvas.height - paddleHeight) {
        playerY = canvas.height - paddleHeight;
      }
    };

    const handleClick = () => {
      if (!gameStartedRef.current || gameOverRef.current) {
        setGameStarted(true);
        setGameOver(false);
        setScore(0);
        setComputerScore(0);
        setWinner("");
        resetBall();
      }
    };

    // Add event listeners
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("click", handleClick);

    // Game loop
    const gameLoop = () => {
      // Update computer speed based on current difficulty
      computerSpeed = calculateComputerSpeed(difficultyRef.current);
      
      // Clear canvas
      context.fillStyle = "#f5f5f4"; // stone-100 bg color
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Draw center line
      context.setLineDash([5, 5]);
      context.beginPath();
      context.moveTo(canvas.width / 2, 0);
      context.lineTo(canvas.width / 2, canvas.height);
      context.strokeStyle = "#78716c"; // stone-400
      context.stroke();
      context.setLineDash([]);

      // Draw paddles
      context.fillStyle = "#44403c"; // stone-700
      context.fillRect(0, playerY, paddleWidth, paddleHeight);
      context.fillRect(
        canvas.width - paddleWidth,
        computerY,
        paddleWidth,
        paddleHeight
      );

      // Draw scores
      context.font = "24px serif";
      context.fillStyle = "#44403c"; // stone-700
      context.fillText(playerScoreRef.current, canvas.width / 4, 30);
      context.fillText(computerScoreRef.current, (3 * canvas.width) / 4, 30);

      // Show start or game over message
      if (!gameStartedRef.current || gameOverRef.current) {
        context.font = "20px serif";
        context.textAlign = "center";

        if (gameOverRef.current) {
          // Use the winner state which is now properly updated
          const winnerText =
            winner || (computerScoreRef.current >= 10 ? "Computer" : "You");
          context.fillText(
            `${winnerText} wins! Click to play again`,
            canvas.width / 2,
            canvas.height / 2
          );
        } else {
          context.fillText(
            "Click to start",
            canvas.width / 2,
            canvas.height / 2
          );
        }

        context.textAlign = "start";
        animationFrameId = requestAnimationFrame(gameLoop);
        return;
      }

      // Game is active - update positions

      // Move the ball
      ballX += ballSpeedX;
      ballY += ballSpeedY;

      // Ball collision with top and bottom walls
      if (ballY - ballRadius < 0 || ballY + ballRadius > canvas.height) {
        ballSpeedY = -ballSpeedY;
      }

      // Ball collision with player's paddle
      if (
        ballX - ballRadius < paddleWidth &&
        ballY > playerY &&
        ballY < playerY + paddleHeight
      ) {
        ballSpeedX = -ballSpeedX;
        // Add some variation based on where the ball hits the paddle
        const deltaY = ballY - (playerY + paddleHeight / 2);
        ballSpeedY = deltaY * 0.2;
      }

      // Ball collision with computer's paddle
      if (
        ballX + ballRadius > canvas.width - paddleWidth &&
        ballY > computerY &&
        ballY < computerY + paddleHeight
      ) {
        ballSpeedX = -ballSpeedX;
        const deltaY = ballY - (computerY + paddleHeight / 2);
        ballSpeedY = deltaY * 0.2;
      }

      // Computer paddle movement (simple AI)
      const computerPaddleCenter = computerY + paddleHeight / 2;
      if (computerPaddleCenter < ballY - 35) {
        computerY += computerSpeed;
      } else if (computerPaddleCenter > ballY + 35) {
        computerY -= computerSpeed;
      }

      // Keep computer paddle within canvas bounds
      if (computerY < 0) {
        computerY = 0;
      }
      if (computerY > canvas.height - paddleHeight) {
        computerY = canvas.height - paddleHeight;
      }

      // Score points
      if (ballX < 0) {
        // Computer scores
        const newScore = computerScoreRef.current + 1;
        setComputerScore(newScore);

        // Check for win condition
        if (newScore >= 10) {
          setGameOver(true);
          setWinner("Computer");
        } else {
          resetBall(-1); // Send ball toward player
        }
      } else if (ballX > canvas.width) {
        // Player scores
        const newScore = playerScoreRef.current + 1;
        setScore(newScore);

        // Check for win condition
        if (newScore >= 10) {
          setGameOver(true);
          setWinner("You");
        } else {
          resetBall(1); // Send ball toward computer
        }
      }

      // Draw ball
      context.beginPath();
      context.arc(ballX, ballY, ballRadius, 0, Math.PI * 2);
      context.fillStyle = "#44403c"; // stone-700
      context.fill();

      animationFrameId = requestAnimationFrame(gameLoop);
    };

    // Start the game loop
    animationFrameId = requestAnimationFrame(gameLoop);

    // Cleanup event listeners and animation on unmount
    return () => {
      cancelAnimationFrame(animationFrameId);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("click", handleClick);
    };
  }, []); // Empty dependency array - initialization runs once

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center min-h-[80vh] bg-stone-100 pt-4">
        <h1 className="text-3xl font-bold mb-6">Pong</h1>
        <div className="relative">
          <canvas
            ref={canvasRef}
            width={600}
            height={400}
            className="border-2 border-stone-700"
          />
        </div>
        <div className="mt-4 text-center">
          <p className="text-lg">
            {/* <span className="font-bold">You:</span> {score} | <span className="font-bold">Computer:</span> {computerScore} */}
          </p>
          <div className="my-6 w-72 mx-auto">
            <div className="flex justify-between items-center mb-3">
              <label htmlFor="difficulty" className="text-sm font-semibold text-stone-700">
                Bot Speed
              </label>
              <div className="px-2.5 py-1 bg-stone-200 rounded-full difficulty-badge shadow-sm">
                <span className="text-sm font-medium text-stone-700">{difficulty}%</span>
              </div>
            </div>
            <div className="relative mt-2">
              <div className="h-2 absolute w-full rounded-full overflow-hidden" style={{ top: "3px" }}>
                <div 
                  className="h-full bg-stone-700 transition-all duration-100 ease-out"
                  style={{ width: `${difficulty}%` }}
                ></div>
              </div>
              <input
                id="difficulty"
                type="range"
                min="0"
                max="100"
                value={difficulty}
                onChange={(e) => setDifficulty(Number(e.target.value))}
                className="difficulty-slider w-full cursor-pointer focus:outline-none relative z-10"
              />
              <div className="flex justify-between mt-2">
                <span className="text-xs font-medium text-stone-500">Slow</span>
                <span className="text-xs font-medium text-stone-500">Fast</span>
              </div>
            </div>
          </div>
          <p className="mt-2 text-sm text-stone-600">
            First to 10 points wins!
            <br />
            Move your mouse up and down to control the paddle on the left.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Pong;
